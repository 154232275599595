@import '../../init';

footer {
  box-shadow: 4px 4px 40px 4px rgba(0, 0, 0, 0.05);
  padding: 2rem;
  display: flex;
  font-weight: 400;
  line-height: 1.5rem;

  .footer-left {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    margin-right: 25%;
  }

  .footer-logo {
    height: 4rem;
    max-width: 16rem;
    margin: 0.5rem;
  }

  .footer-links {
    font-size: 1rem;
    max-width: 60%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-right: 5%;

    @media (max-width: 1004px) {
      width: 90%;
      max-width: unset;
    }

    .hide {
      display: none;
    }

    p {
      font-size: 0.9rem;

      &:hover {
        opacity: 0.4;
      }
    }

    &:a {
      font-size: 0.9rem;

      &:hover {
        opacity: 0.4;
      }
    }
  }

  .social-box {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main);
    font-size: 1.5rem;
    width: 3rem;
    height: 3rem;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin: 0.5rem;
  }

  @media (--screen-sm) {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .footer-left {
      display: flex;
      flex-direction: column;
      margin: 0;
      justify-content: space-around;

      .footer-logo {
        align-self: center;
      }
    }

    .socials {
      padding: 5%;
      margin: auto;
    }

    .footer-links {
      display: block;
      margin: auto;
    }
  }
}

