@import '../../init';

.splide {
  @media (--screen-sm) {
    width: 110%;
    margin: 0 -5% 0 -5%;
  }
}

.white_bg {
  background-color: white;
}

.splide__pagination__page {
  margin: 5px;
  width: 12px;
  height: 12px;
  border-radius: 10px;
  opacity: 1;
}

.splide__pagination__page.is-active {
  background: #1882ff;
}
