@import '../../init';

.picto_container {
  &--home_first {
    img {
      max-width: 60%;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &--top_right {
    img {
      max-width: 20%;
      position: absolute;
      top: -53%;
      right: 0;
    }
  }

  &--discover {
    img {
      position: absolute;
      top: -29rem;
      right: 2rem;
    }
  }

  &--price {
    img {
      max-width: 20rem;
      position: absolute;
      top: -13rem;
      right: 3rem;
    }
  }

  &--steps {
    img {
      max-width: 30rem;
      max-height: auto;
      position: absolute;
      top: -9rem;
      left: -20rem;
    }
  }

  &--quote {
    img {
      position: absolute;
      top: -2.3rem;
      right: 2.2rem;
    }
  }

  &--join_team {
    img {
      position: absolute;
      top: -18rem;
      right: 1rem;
    }
  }

  &--newsletter {
    img {
      max-width: 50%;
      position: absolute;
      top: -17rem;
      right: -3rem;
    }
  }

  &--in_progress {
    text-align: center;
    img {
      margin: 5vh auto;
    }
  }

  @media (--screen-sm) {
    &--price,
    &--steps,
    &--join_team,
    &--newsletter, {
      display: none;
    }
  }
}
