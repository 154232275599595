@import "../../init";

.page {
  padding-top: 2rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--font-color);
  position: relative;
  min-height: calc(100vh - 14rem);

  .hide {
    display: none
  }

  .employee_account_info {
    display: flex;
    margin: 3rem;
    img {
      height: 8rem;
    }
    @media (--screen-sm) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .employee_account_details {
    margin-left: 4rem;
    @media (--screen-sm) {
      margin: 0.2rem;
    }
  }

  .centered {
    text-align: center;  
  }

  .employee_account_quotes {
    display: flex;
    justify-content: space-between;
    margin: 2rem;
    @media (--screen-sm) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .employee_account_quote {
    border-radius: 16px;
    padding: 2rem;
    background-color: #FFFCF3;
    margin: 1rem;
    width: 50%;
    .quote{
      color: #FFC700;
    }

    @media (--screen-sm) {
      width: 100%;
    }

  }

  p {
    font-size: 1.1rem;
  }

  h2 {
    font-size: 2rem;
    line-height: 2rem;
    margin: 2em 0 0 0;
    color: var(--main);
    font-weight: 400;
    margin: 1rem 0;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin: 1rem 0;
  }

  .video_section {
    text-align: center;

    .video {
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;

      iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
      }
    }
  }

  .payment_infos {
    display: flex;
    flex-direction: column;

    h2 {
      color: var(--font-color);
      margin: 3rem 0;
      font-weight: 600;
    }

    h3 {
      color: var(--main);
      margin-bottom: 2rem;
      font-weight: 600;
    }

    .bolder {
      font-weight: 500;
    }

    .dashboard_link {
      display: flex;
      align-items: center;

      p {
        margin: 0;
      }

      img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
      }
    }

    .rib {
      text-align: center;
      align-self: center;
      margin: 4rem;
      width: 35rem;
    }

    .payments_order {
      margin-bottom: 6rem;

      .payment {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        p {
          margin: 0;
        }

        .payment_list {
          color: var(--blue);
          font-size: 2rem;
          width: 1.5rem;
          margin-right: 1rem;
        }
      }
    }
  }

  .offer-content {
    display: flex;
    margin: 2rem 0;

    .offer-text {
      margin-left: 5%;
    }
  }

  .plan-content {
    margin-bottom: 3rem;

    h3 {
      font-weight: 600;
    }
  }

  .promo-title {
    text-align: center;
    margin-top: 4rem;
    font-weight: 600;
  }

  .promo-details {
    font-size: 1.1rem;
    margin-bottom: 4rem;
  }

  .on_yellow {
    background-color: #FFC7001C;
    font-size: 28px;
    padding: 4px;
  }

  .invitation-arguments {
    display: flex;
    justify-self: center;
    margin: 2rem 0;

    @media (--screen-sm) {
      display: block;
    }

    .invitation-argument-column {
      display: flex;
      flex-direction: column;
      margin: 0 3rem;
      align-items: center;
      text-align: center;
      justify-content: center;

      .regular-offer {
        position: relative;
        font-weight: 600;
        width: fit-content;
        height: fit-content;

        img {
          position: absolute;
          top: 1.2rem;
          left: -0.5rem;
          height: 1.5rem;
        }
      }

      .subtitle {
        line-height: 1rem;
        margin-bottom: 2rem;
      }
    }
  }

  .invitation-box {
    background-color: #ffc7001a;
    padding: 5% 10% ;
    margin: 0 auto 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1rem;

    .sponsorship_code {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1882FF;
      font-size: 1.5rem;
      font-weight: 700;
      border: 1px solid rgba(20, 20, 20, .1);
      border-radius: 8px;
      background-color: white;
      width: 18rem;
      height: 3rem;
    }
  }

  .accept-form-box {
    border-radius: 10px;
    box-shadow: 0.3rem 0.3rem 1.5rem 0.3rem rgba(229, 229, 229, 0.7);
    padding: 2rem 3rem;
    margin: 0 auto 4rem;
    display: flex;
    justify-content: center;
    max-width: 80%;

    .accept-invitation {
      h2 {
        margin-bottom: 2rem;
      }

      .form {
        width: 30rem;
        margin: 0 auto;

        .submit {
          text-align: center;
        }
      }
    }
  }

  .password_box {
    border-radius: 10px;
    box-shadow: 0.3rem 0.3rem 1.5rem 0.3rem rgba(229, 229, 229, 0.7);
    padding: 3rem 4rem;
    margin: 2.5rem auto;
    display: flex;
    justify-content: center;
    max-width: 60%;

    .password {
      h2 {
        margin-bottom: 3rem;
      }

      .form {
        width: 18rem;
        margin: 0 auto;

        .submit {
          text-align: center;
        }
      }

      .sign_in_link {
        text-align: center;
        margin-top: 1.5rem;
        color: var(--blue);
        font-weight: 600;
      }
    }
  }

  .more_quotes {
    display: flex;
    align-items: center;
    margin: 0 0 15% 20%;

    .plus {
      color: blue;
      font-size: 2rem;
      margin: 0 1rem 0 0;
    }

    .text {
      font-weight: 700;
      margin: 0;
    }

    @media (--screen-sm) {
      text-align: center;
      margin-top: 1rem;
    }
  }

  .discover {
    margin-top: 30rem;

    @media (--screen-sm) {
      margin-top: 0;
    }
  }

  .client-witness {
    display: flex;
    margin: 0 -5rem 0 -5rem;

    .client-quotes-col-left {
      width: 50rem;
      margin-right: 10%;
      margin-bottom: 4rem;


      h3 {
        line-height: 2rem;
        margin-bottom: 2rem;
      }

      a {
        width: 100%;
      }
    }

    .client-quotes {
      width: 40rem;
    }

    .col-1 {
      margin-top: 4rem;
    }
  }

  .client-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    img {
      margin: auto;
    }
  }

  .contact-title {
    width: 70%;
  }

  .contact-details {
    max-width: 34%;
    margin: 4rem;

    p {
      margin-left: 2rem;
    }
  }

  .team {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    margin: 4rem 0 24rem 0;
  }

  .join_team {
    margin: 3rem;
  }

  @media (--screen-xs) {
    p {
      font-size: 1rem;
    }

    h2 {
      font-size: 1.5rem;
      text-align: center;
      margin: auto;
    }

    h3 {
      font-size: 1.5rem;
      text-align: center;
      margin: auto;
    }

    .client-witness {
      display: block;
      margin: 0;

      .client-quotes-col-left {
        width: 100%;

        h3 {
          text-align: center;
          line-height: 2rem;
          margin-bottom: 2rem;
          text-align: center;
        }

        img {
          width: 90%;
        }

        a {
          max-width: 90%;
        }
      }

      .client-quotes {
        width: 100%;
      }

      .col-1 {
        margin: 0;
      }
    }

    .contact-details {
      max-width: 100%;
      margin: 2rem 0;

      p {
        margin-left: 1rem;
      }
    }

    .team {
      grid-template-columns: 1fr;
      align-items: center;
      grid-gap: 1rem;
      margin-bottom: 2rem;
    }

    .join_team {
      margin: -1rem;
    }
  }
}
