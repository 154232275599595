@import "../../init";


section {
  position: relative;
  width: 100%;
  margin-bottom: 5rem;
}

.section {
  &--home_page_first_section {
    background-color: var(--main);
    height: calc(100vh - 2rem);
    margin-top: -6rem;
    padding-top: 5%;

    .home_page_first_section {
      &__content {
        margin-left: 10%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 40%;
        color: var(--white);
        margin-top: 1rem;
      }

      &__title {
        font-size: 2rem;
        line-height: 1.5em;
        font-weight: 600;
        text-align: left;
      }

      &__catchphrase {
        font-size: 1.5rem;
        line-height: 1.5em;
        font-weight: 500;
        text-align: left;
      }
    }

    @media (--screen-sm) {
      .home_page_first_section {
        &__content {
          width: 100%;
          max-height: 95%;
          margin: 3rem 0 0;
          padding: 5%;
          align-items: center;
        }

        &__title {
          text-align: center;
          font-size: 2.5rem;
        }

        &__catchphrase {
          width: 100%;
          text-align: center;
          font-size: 2rem;
        }
      }
    }
  }

  &--home_page_price_section {
    background-color: var(--main);
    color: white;
    padding: 5%;

    .home_page_price_section {
      &__title {
        height: 30%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        font-weight: 700;
      }

      &__price_main {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
      }

      &__price_block {
        display: flex;
        align-items: center;
        max-height: 4rem;
        padding: 4rem;
      }

      &__price {
        font-weight: 900;
        font-size: 8rem;
        grid-column: 1;
        align-self: center;
        margin: 0;
      }

      &__side1 {
        font-weight: 900;
        font-size: 4rem;
        margin: 0;
        margin-top: 1rem;
      }

      &__side2 {
        font-weight: 900;
        font-size: 3rem;
        margin: 0;
        margin-top: 1.5rem;
      }

      &__price_arguments {
        padding: 1rem;

        p {
          font-size: 1.25rem;
          line-height: 1.1rem;
        }
      }
    }

    @media (--screen-sm) {
      .home_page_price_section {
        &__title {
          text-align: center;
          height: 30%;
          padding-top: 5%;
        }

        &__price_block {
          height: 30%;
          padding: 4rem 0;
        }

        &__price {
          font-size: 6rem;
        }

        &__side1 {
          font-size: 3rem;
          margin: 0;
          margin-top: 1rem;
        }

        &__side2 {
          font-weight: 900;
          font-size: 2rem;
          margin: 0;
          margin-top: 0.5rem;
        }

        &__price_arguments {
          text-align: center;
          height: 40%;

          p {
            line-height: 1.2rem;
          }
        }
      }
    }
  }

  &--home_page_steps_section {
    .steps-title {
      text-align: right;
      margin-right: 5%;
      font-size: 1.5rem;
    }

    .contract-steps-1-4 {
      display: flex;
      justify-content: space-around;

      .contract-step {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        max-width: 25%;
        margin: 0 1rem;
      }
    }

    .contract-steps-5-6 {
      position: relative;
      margin-left: 30%;
      margin-right: 5%;

      .contract-step {
        display: flex;
        align-items: center;
        margin-left: 25%;
      }
    }

    .contract-steps-buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      a {
        margin: 1rem;
      }
    }

    @media (--screen-sm) {
      .steps-title {
        text-align: center;
        margin: auto;
      }

      .contract-steps-1-4 {
        display: block;
        text-align: center;

        .contract-step {
          display: flex;
          text-align: center;
          align-items: center;
          max-width: 100%;
          margin: 0;
        }
      }

      .contract-steps-5-6 {
        text-align: center;
        margin: 0;

        .contract-step {
          flex-direction: column;
          align-items: center;
          max-width: 100%;
          margin: 0;
        }
      }
    }
  }
}



