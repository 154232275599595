@import '../../init';

.slide_quote {
  .quotes {
    margin: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .quote {
      max-width: 50%;
    }
  }

  @media (--screen-sm) {
    .quotes {
      flex-direction: column;
      margin: 10% 0;

      .quote {
        max-width: 90%;
      }
    }
  }
}

