@import '../../init';

.slide_home {
  margin: 2rem 4rem;
  display: flex;
  align-items: center;
  justify-content: space-beween;
  height: 100%;

  img {
    max-width: 60%;
  }

  .text {
    a {
      color: grey;
      background-color: white;
      box-shadow: 0.3rem 0.3rem 1.5rem 0.3rem rgba(229, 229, 229, 0.7);
      padding: 1rem 4rem;
    }
  }

  @media (--screen-sm) {
    display: block;

    img {
      max-width: 130%;
      margin-left: -15%;
    }

    .text {
      width: 130%;
      margin-left: -15%;
    }
  }
}

