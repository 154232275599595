@import '../../init';

.double_button_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 30rem;

  a {
    margin-top: 2rem;
  }

  @media (--screen-sm) {
    flex-direction: column;

    .btn {
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }
}

