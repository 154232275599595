@import '../../init';

.slide_discover {
  margin: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (--screen-sm) {

    img {
      display: none;
    }
  }
}
