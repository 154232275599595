@import '../../init';

.navbar-wrapper {
  background: var(--main);
  margin: 0;
  padding: 0 2rem;
  position: sticky;
  top: 0;
  width: 100%;
  height: 6rem;
  z-index: 2;
  font-size: 1rem;
}

.hide {
  display: none;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;

  img {
    width: 11rem;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    width: 30rem;
  }

  &__links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 40%;

    a {
      margin: 0 0.5rem;
      display: flex;
      align-items: center;

      img {
        width: 1.5rem;
        margin-left: 0.5rem;
        margin-top: 0.2rem;
      }

      &:hover {
        opacity: 0.4;
      }
    }
  }

  &__dropdown-menu {
    display: none;
    position: absolute;
    background: white;
    border-radius: 8px;
    text-align: right;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;

    a {
      display: block;
      margin: 0.5rem;
    }
  }

  .show {
    display: block;
  }

  &__mobile-menu {
    display: none;
    color: white;
    font-size: 1.5rem;
  }

  &__link {
    opacity: 1;
    margin-right: 1em;
    cursor: pointer;
  }

  .mobile-sidebar {
    position: fixed;
    background-color: var(--main);
    width: 100vw;
    right: -100vw;
    top: 6rem;
    bottom: 0;
    z-index: 20;
    transition: right ease 0.5s;
    padding: 2em;

    &.visible {
      right: 0;
    }

    &__close {
      font-size: 2rem;
      color: var(--white);
    }

    &__content {
      margin: 3em 0;
      text-align: center;

      a {
        display: block;
        margin: 1em auto;
        align-self: center;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  @media (--screen-md) {
    &__buttons {
      width: 100%;
    }

    &__links {
      display: none;
    }

    &__mobile-menu {
      display: block;
      margin: 1rem;

      .hide {
        display: none;
      }
    }
  }

  @media (--screen-sm) {
    &__links {
      display: none;
    }

    &__buttons {
      display: none;
    }

    &__mobile-menu {
      display: block;
      margin: 1rem;

      .hide {
        display: none;
      }
    }
  }
}

