@import '../../init';

.home_page_popup_container {
  position: fixed;
  z-index: 3;
  display: flex;
  justify-items: center;
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(0, 0, 0, 0.4);

  .home_page_popup {
    align-self: center;
    position: relative;
    max-width: 60%;
    margin: 15% auto;
    background-color: white;
    width: 34rem;
    padding: 2rem 3rem;
    border-radius: 8px;
    line-height: 1.2rem;

    .title {
      color: var(--blue);
      font-weight: 600;
      font-size: 1.2rem;
    }

    .js-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background-color: rgba(116, 180, 248, 0.1);
      border-radius: 100%;
      color: var(--blue);
      line-height: 0;
      padding: 0.5rem;
      width: 1rem;
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.2rem;
      cursor: pointer;

      &:hover {
        opacity: 0.4;
      }
      img {
        width: 1.3rem;
      }
    }

    a {
      margin-top: 1rem;
      width: 12rem;
    }
  }
}
