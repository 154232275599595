@import "../../init";

.small_square_box {
  display: flex;
  justify-self: center;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  min-width: 3.5rem;
  min-height: 3.5rem;
  max-width: 3.5rem;
  max-height: 3.5rem;
  font-size: 2.7em;
  font-weight: 900;
  margin: 1rem;
}

.number {
  color: var(--main);
  background-color: rgb(253, 225, 139, 0.7);
}

.icon {
  background-color: white;
  box-shadow: 0.3rem 0.3rem 1.5rem 0.3rem rgba(229, 229, 229, 0.7);
}
