@import "../../init";

.newsletter_box {
  background-color: #ffc7001a;
  padding: 5% 25% 5% 20%;
  margin: 0 auto;
}
  .newsletter_form {
    display: flex;
    justify-content: space-around;
    margin: 10% 0 0 0;
    .input {
      display: block;
      width: 50%;
      box-sizing: border-box;
      outline: none;
      border: none;
      border-bottom: 1px solid #c4c4c4;
      padding: 8px 0 8px 8px;
      font-size: 1rem;
      line-height: 1.25rem;
      font-family: 'Barlow';
      background-color: white;
      color: #333;
    }
    .new_input {
      display: none;
    }
    .submit {
      text-transform: none;
      text-align: center;
      color: white;
      background: #FFC700;
      border: 2px solid #FFC700;
      display: inline-block;
      cursor: pointer;
      max-width: 22rem;
      border-radius: 7px;
      padding: 1.2em 4em;
      font-weight: 700;
      &:hover{
        opacity: 0.6;
      }
    }
  }

  @media (--screen-xs) {
   .newsletter_form {
    display: block;
    text-align: center;
    .input {
      max-width: 100%;
      margin-bottom: 1rem;
    }
    .submit {
      max-width: 100%;
    }
  }
}

