@import '../../init';

.container {
  margin-left: 10%;
  margin-right: 10%;
}

  @media (--screen-xs) {
  .container {
    margin-left: 5%;
    margin-right: 5%;
  }
  }
