@import '../../init';

.staff_member_card {
  display: flex;
  align-items: center;
  box-shadow: 0.3rem 0.3rem 1.5rem 0.3rem rgba(229, 229, 229, 0.7);
  border-radius: 8px;

  .img-content {
    display: flex;
    align-items: center;
    margin: 1rem;
    img {
      max-width: 6rem;
      max-height: 6rem;
    }
  }

  .staff_member_infos {
    margin-left: 1rem;
  }

  .fullname {
    color: var(--main);
    margin: 0;
  }
  .function {
    margin: 0;
  }
}

  @media (--screen-xs) {
    .staff_member_card {
    padding: 0.25rem;
      .img-content {
        img {
          max-width: 4rem;
          max-height: 4rem;
        }
      }
    }
  }
