@import "colors.css";

.center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.italic {
  font-style: italic;
}

.flex {
  display: flex;
}

.flex--column {
  flex-direction: column;
}

.align-items {
  align-items: center;
}

.center-items {
  justify-content: center;
}

.bold {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.orange {
  color: var(--main);
}

.red {
  color: var(--red);
}

.blue {
  color: var(--blue);
}

.green {
  color: var(--green);
}

.no-margin {
  margin: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.margin-bottom {
  margin-bottom: 2rem;
}

.margin-left {
  margin-left: 20rem;
}

.not-visible {
  max-height: 0 !important;
  overflow: hidden;
  transition: max-height 1s;
}

.display--none {
  display: none;
}

.only-on-mobile {
  display: none;

  @media (--screen-xs) {
    display: inherit;
  }
}

.hidden-on-mobile {
  @media (--screen-xs) {
    display: none !important;
  }
}

.underlined {
  text-decoration: underline;
}

.focus {
  background-color: var(--special-color);
  display: inline;
  font-weight: bold;
}
