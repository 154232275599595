@import '../../init';

.alert-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: 100%;
  background: var(--blue);
  color: white;
  font-size: 1.2rem;
  padding: 1rem 0;
  .alert {
    text-align: center;
    max-width: 65%;
    line-height: 1.6rem;
  }

  .fas {
    position: absolute;
    right: 3rem;
    margin: auto;
    font-size: 1.5rem;

    &:hover {
      cursor: pointer;
      opacity: 0.4;
    }

    @media (--screen-sm) {
      display: none;
    }
  }
}
