@import '../../init';

.box {
  position: relative;
  border-radius: 10px;
  box-shadow: 0.3rem 0.3rem 1.5rem 0.3rem rgba(229, 229, 229, 0.7);

  &.large {
    padding: 1.5rem 3rem;
    margin: 1rem;
  }

  &.small {
    padding: 1rem 1.5rem;
    margin: 1rem;
  }
}

