@import '../../init';

.alert-container {
  position: relative;
  display: flex;
  z-index: 2;
  width: 100%;
  background: var(--blue);
  color: white;
  font-size: 1rem;
  padding: 0.5rem 0;

  .alert {
    max-width: 55%;
  }

  .new-feature {
    border: 1px solid;
    color: #1882FF;
    background-color: white;
    border-radius: 8px;
    padding: 0.15rem 0.4rem;
    font-weight: 900;
  }

  .fas {
    position: absolute;
    right: 3rem;
    margin: auto;
    font-size: 1.5rem;

    &:hover {
      cursor: pointer;
      opacity: 0.4;
    }

    @media (--screen-sm) {
      display: none;
    }
  }
}
