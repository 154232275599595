@import "normalize.css/normalize.css";
@import "fonts.css";
@import "colors.css";
@import "utilities.css";

/* Media queries */
@custom-media --screen-xs (max-width: 440px);
@custom-media --screen-sm (max-width: 756px);
@custom-media --screen-md (max-width: 1004px);
@custom-media --screen-lg (max-width: 1400px);
@custom-media --screen-xl (max-width: 1600px);

* {
  box-sizing: border-box;

  @media (max-width: 1004px) {
    font-size: 14px;
  }

  @media (max-width: 845px) {
    font-size: 13px;
  }

  @media (max-width: 756px) {
    font-size: 12px;
  }

  @media (max-width: 480px) {
    font-size: 11px;
  }
}

body {
  font-family: "Barlow", sans-serif;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--font-color);
  position: relative;
}

a {
  text-decoration: none;
  color: inherit;
}

a.link {
  font-size: 0.8rem;
  opacity: 1;

  &:hover {
    opacity: 0.6;
  }
}

h2 {
  font-family: 'Barlow', sans-serif;
  margin: 2em 0;
}

h3 {
  font-family: 'Barlow', sans-serif;
  font-size: 1.2rem;
  font-weight: 200;
  margin: 0.5em 0 1.5em;
}

p {
  &.small {
    font-size: 0.8rem;
    opacity: 0.8;
    margin: 0;
  }

  @media (--screen-xs) {
    margin: 0.5em 0;
  }
}

span.small {
  font-size: 0.8rem;
  opacity: 0.8;
}
